//import "./styles.css";

import React from 'react'
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { userSelector } from '../slices/authSlice';
import { useNavigate, useLocation } from 'react-router-dom';

import axios from 'axios';
import OrderDetails from "../components/OrderDetails";
import download from 'downloadjs';
import { setUnshippedOrders, unshippedOrdersSelector } from '../slices/orderSlice';

import { getActiveOrders } from '../api/orders';

function UserArea() {

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    var orders = useSelector(unshippedOrdersSelector)
    var user = useSelector(userSelector);

    const [active, setActive] = useState(false)
    const [ordersCount, setOrdersCount] = useState(0)
    const [ordersWeight, setOrdersWeight] = useState(0)

    useEffect(() => {
        if (!user)
            navigate('/')
    }, [user, navigate]);

    useEffect(() => {

        const getToken = async (code, state) => {
            console.log('Getting token')
            const result = await axios.get(
                `/api/etsy/oauth/redirect`, {
                withCredentials: true,
                params: { code, state }
            });

            console.log(result.data)
        }

        const queryParams = new URLSearchParams(location.search);
        const code = queryParams.get('code');
        const state = queryParams.get('state');

        if (code) {
            getToken(code, state)
        }
    }, [location])

    const authorize = async e => {
        try {
            console.log('Authenticating...')
            const result = await axios.get(
                '/api/etsy/authenticate',
                { withCredentials: true }
            );

            console.log(result.data)

            if (typeof result.data === 'string' || result.data instanceof String) {
                window.open(result.data, '_blank').focus();
            }

            setActive(true);
        }
        catch (err) {
            console.log('Failed to authenticate')
        }
    }

    const unshippedOrdersBtnClick = async e => {
        const orders = await getActiveOrders()
        dispatch(setUnshippedOrders(orders))
    }

    const getCTTShippingInformation = async e => {
        const result = await axios.post(
            '/api/etsy/createCTTShippingInformation',
            { withCredentials: true, data: [2558540853, 2562643862] },
            { responseType: 'blob' }
        );

        const fileName = result.headers['content-disposition'].split('=')[1]
        console.log(result, fileName)

        download(result.data, fileName);
    }

    const getUnshippedOrderInfo = async () => {
        const result = await axios.get('/api/etsy/cttShippingInformation')
	setOrdersCount(result.data.count)
	setOrdersWeight(result.data.weight)
    }

    return (
        <div className="user-area">
            <h1>Welcome, {user && user.name}</h1>
            {/* <a href='/customers'>Customers</a> */}
            <button onClick={authorize}>Authorize</button>
            <button onClick={unshippedOrdersBtnClick}>Unshipped Orders</button>
            <button onClick={getCTTShippingInformation}>CTT</button>
            <a href="/utils" className="link-button">PDF Resizer</a>

	    <button onClick={getUnshippedOrderInfo}>get info</button>

	    <p>Orders: {ordersCount}</p>
	    <p>Weight: {ordersWeight}</p>

            <ul style={{ listStyle: 'none', margin: '1rem auto', padding: '0', display: 'flex', flexWrap: 'wrap', justifyContent: 'start', width: '90vw' }}>
                {orders.map((o, i) => <li key={i}><OrderDetails order={o} /></li>)}
            </ul>
        </div >
    )
}

export default UserArea
